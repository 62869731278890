import { SkeletonLoader } from '@hexa-ui/components';
import { Box } from '@material-ui/core';
import { useDecision } from '@optimizely/react-sdk';
import { useUserMetadata } from 'admin-portal-shared-services';
import { PageName, SpecificApp } from 'analytics';
import { IndicatorEnum } from 'components/Badge/Badge';
import { DataChunkProvider } from 'context/DataChunkContext';
import UserInfoContext from 'context/UserInfoContext';
import { useAppHeaderService } from 'hooks/useAppHeaderService';
import useEventPageProps from 'hooks/useEventPageProps';
import { useSegmentEvent } from 'hooks/useSegmentAnalytics';
import { formatMessage } from 'i18n/formatters';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ReactJson from 'react-json-view';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { BASE_URL, MONITORINGPAGE_URL } from 'Router';
import { useGetPayloadDetailsQuery, useGetRecordDetailsQuery } from 'services/baseApi';
import {
  getChunkWithCustomStatus,
  getSteps,
  IExtendedMonitoringDetailsRecord,
  isRecordExactMatch,
} from 'services/monitoring/recordService';
import { setUserLocalData } from 'services/userLocalData/UserLocalDataService';
import CardErrorDetails from '../Monitoring/components/v2/components/CardErrorDetails/CardErrorDetails';
import CardPayload from '../Monitoring/components/v2/components/CardPayload/CardPayload';
import ChunkUpdateInfoCard from '../Monitoring/components/v2/components/ChunkUpdateInfoCard/ChunkUpdateInfoCard';
import HeaderStatus from '../Monitoring/components/v2/components/HeaderStatus/HeaderStatus';
import {
  addPageToHistory,
  hasPageInHistory,
  updateUrlValue,
} from '../Monitoring/components/v2/FilterContainer/utils';
import './DataChunkDetails.css';

function DataChunkDetails(): JSX.Element {
  const {
    selectedCountry: country,
    selectedVendor: vendor,
    setUserInfoContext,
  } = useContext(UserInfoContext);
  const { data: metadata } = useUserMetadata();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { pathname } = useLocation();
  const cachedPayload = sessionStorage.getItem(id);
  const payloadInitialState = cachedPayload ? JSON.parse(cachedPayload) : { data: null };
  const [payloadDetails, setPayloadDetails] = useState(payloadInitialState);
  const event = useSegmentEvent();

  const urlParams = useMemo(() => {
    const params = window.location.search.substring(1);
    return Object.fromEntries(new URLSearchParams(params));
  }, [window.location.search]);

  useEffect(() => {
    addPageToHistory('DataChunkDetails'); // adds this route to the local history
  }, []);

  useAppHeaderService({
    hidePageTitle: true,
    breadcrumbConfig: {
      homePath: BASE_URL,
      items: [
        {
          label: formatMessage({ id: 'MonitoringPage.TITLE' }),
          isCurrentPage: false,
          path: MONITORINGPAGE_URL,
          onClick: hasPageInHistory('MonitoringView')
            ? () => history.goBack()
            : () => history.push(MONITORINGPAGE_URL),
        },
        {
          label: formatMessage({ id: 'MonitoringPage.DATA_RESULTS_AVALIABLE' }),
          isCurrentPage: false,
          path: MONITORINGPAGE_URL,
          onClick: hasPageInHistory('MonitoringDetails')
            ? () => history.goBack()
            : () => history.push(MONITORINGPAGE_URL),
        },
        {
          label: formatMessage({ id: 'MonitoringPage.DATA_CHUNK_DETAILS' }),
          isCurrentPage: true,
          path: pathname,
        },
      ],
    },
    countrySelect: false,
    vendorSelect: false,
  });

  useEffect(() => {
    const { vendors = [] } = metadata || {};
    const { country: urlCountry, vendor: urlVendor } = urlParams;

    if (urlCountry && urlVendor && urlCountry !== 'undefined' && urlVendor !== 'undefined') {
      setUserInfoContext({ selectedCountry: urlCountry, selectedVendor: urlVendor });

      const vendorObj = vendors.find((vendorOption) => vendorOption.id === urlVendor) || {};
      setUserLocalData({
        selectedCountry: urlCountry,
        selectedVendor: {
          id: vendorObj.id ?? '',
          name: vendorObj.displayName ?? '',
          serviceModel: '',
          country: '',
          isManufacturer: false,
        },
      });
    } else {
      updateUrlValue('country', country);
      updateUrlValue('vendor', vendor);
    }
  }, [country, vendor, urlParams, metadata]);

  const updatedPayloadDetails = useGetPayloadDetailsQuery(
    { country, vendor, id },
    { skip: !country || !vendor || !!payloadDetails.data }
  );

  useEffect(() => {
    if (!payloadDetails.data && updatedPayloadDetails.data) {
      sessionStorage.setItem(id, JSON.stringify(updatedPayloadDetails));
      setPayloadDetails(updatedPayloadDetails);
    } else if (cachedPayload) {
      setPayloadDetails(JSON.parse(cachedPayload));
    }
  }, [updatedPayloadDetails]);

  const records = useGetRecordDetailsQuery(
    {
      country,
      vendor,
      'parent-trace-id': payloadDetails.data?.parentTraceId,
    },
    { skip: !country || !vendor || !payloadDetails.data?.traceId }
  );

  const isLoading = useMemo(
    () => payloadDetails.isLoading || records.isLoading,
    [payloadDetails.isLoading, records.isLoading]
  );

  const overrideAttributes = useMemo(
    () => ({
      entity: payloadDetails.data?.entity.name ?? '',
      version: payloadDetails.data?.entity.version.replace(' ', '_').toUpperCase() ?? '',
      country: country ?? '',
      operation: payloadDetails.data?.entity.operation ?? '',
      vendorId: vendor ?? '',
    }),
    [payloadDetails.data, country, vendor]
  );

  const VSFeedBackLayerDecision = useDecision(
    'feedback_layer_segmented_config',
    { autoUpdate: true },
    { overrideAttributes }
  );

  const VSFeedbackLayerEnabled = useMemo(
    () => VSFeedBackLayerDecision[0]?.variables?.isEntityIntegratedWithSync as boolean,
    [VSFeedBackLayerDecision]
  );

  const showPayload = useMemo(
    () => (payloadDetails.data ? isRecordExactMatch(payloadDetails.data) : false),
    [payloadDetails.data]
  );

  const getRecord = useCallback(
    () => records.data?.apiResponse?.find((record) => record.id === id),
    [country, vendor, records]
  );

  const record = getRecord();

  const recordSteps = useMemo(() => {
    if (records.data?.apiResponse && record) {
      const steps = getSteps(
        record as IExtendedMonitoringDetailsRecord,
        records.data.apiResponse as IExtendedMonitoringDetailsRecord[],
        VSFeedbackLayerEnabled
      );

      return steps;
    }

    return null;
  }, [payloadDetails.data, records.data, VSFeedbackLayerEnabled]);

  const allSteps = useMemo(() => {
    const steps = ['DATA_ENTRY', 'ADHERENCE_VALIDATION', 'DATA_DISTRIBUTION', 'CONFIRMATION'];

    if (!recordSteps?.[0]) {
      return null;
    }

    return steps.slice(0, steps.indexOf(recordSteps[0]) + 1);
  }, [recordSteps]);

  const recordCustomStatus = useMemo(() => {
    if (records.data && record && recordSteps?.[0]) {
      const status = getChunkWithCustomStatus(
        record,
        records.data.apiResponse,
        recordSteps[0],
        VSFeedbackLayerEnabled
      );

      return status;
    }

    return null;
  }, [payloadDetails.data, records.data, recordSteps?.[0], VSFeedbackLayerEnabled]);

  const badgeStatus = useMemo(() => {
    const status = recordCustomStatus?.status.customStatus;
    switch (status) {
      case 'IN_PROGRESS':
        return IndicatorEnum.PROCESSING;
      case 'COMPLETED':
        return IndicatorEnum.CONCLUDED;
      default:
        return IndicatorEnum.FAILED;
    }
  }, [payloadDetails.data, recordCustomStatus]);

  useEventPageProps(
    {
      page_name: PageName.BeesSyncMonitoringDataChunkDetails,
    },
    {
      specific_app: SpecificApp.Monitoring,
      is_core_event: true,
      data_chunk_trace_id: id,
      status: recordCustomStatus?.status.customStatus,
      data_chunk_step: recordSteps?.[0],
      data_chunk_source_system: payloadDetails.data?.sourceSystem,
      entity: payloadDetails.data?.entity.name,
      action: payloadDetails.data?.entity.operation,
      version: payloadDetails.data?.entity.version,
      data_chunk_error_message: payloadDetails.data?.status.message,
      data_chunk_payload_present: !!payloadDetails.data?.payload,
      all_available_ingestion_steps: allSteps,
      parent_trace_id: payloadDetails.data?.parentTraceId,
    }
  );

  const dataJSON = useMemo(
    () => JSON.parse(payloadDetails.data?.payload ?? '{}'),
    [payloadDetails.data]
  );

  const downloadJSON = useCallback(() => {
    const link = document.createElement('a');
    const returnString = payloadDetails.data?.payload ?? '{}';
    link.href = URL.createObjectURL(new Blob([returnString], { type: 'application/json' }));
    link.download = 'payload.json';
    link.click();
  }, [payloadDetails.data]);

  return isLoading ? (
    <Box>
      <Box className="wrapper-box">
        <SkeletonLoader width="400px" height="50px" variant="body" />
      </Box>
      <Box className="wrapper-box">
        <SkeletonLoader width="100%" height="148px" variant="body" />
      </Box>
      <Box className="wrapper-box">
        <SkeletonLoader width="100%" height="148px" variant="body" />
      </Box>
      <Box className="wrapper-box">
        <SkeletonLoader width="100%" height="420px" variant="body" />
      </Box>
    </Box>
  ) : (
    <DataChunkProvider
      chunkDetails={{
        id,
        status: payloadDetails.data?.status.name ?? '',
        traceId: payloadDetails.data?.traceId ?? '',
        step: recordSteps?.[0] ?? '',
        sourceSystem: payloadDetails.data?.sourceSystem ?? '',
        entity: payloadDetails.data?.entity.name ?? '',
        action: payloadDetails.data?.entity.operation ?? '',
        version: payloadDetails.data?.entity.version ?? '',
        errorMessage: payloadDetails.data?.status.message ?? '',
        payload: payloadDetails.data?.payload ?? '',
        errorCode: payloadDetails.data?.status?.code ?? '',
      }}
    >
      <Box data-testid="data-chunk-details">
        {payloadDetails.data && (
          <Box>
            <HeaderStatus
              header="Data chunk details"
              badgeStatus={badgeStatus}
              previousPageName="MonitoringDetails"
            />
            <Box className="wrapper-box">
              <ChunkUpdateInfoCard
                isDataChunkDetails
                entity={payloadDetails.data?.entity.name ?? '-'}
                sourceSystem={payloadDetails.data?.sourceSystem ?? '-'}
                traceID={payloadDetails.data?.traceId ?? '-'}
                version={payloadDetails.data?.entity.version ?? '-'}
                action={payloadDetails.data?.entity.operation ?? '-'}
                step={recordSteps?.[0] ?? '-'}
                segmentProps={{
                  payload: payloadDetails.data?.payload,
                  errorMessage: payloadDetails.data.status.message,
                  status: badgeStatus,
                  event,
                }}
              />
            </Box>
          </Box>
        )}
        {payloadDetails.data?.status.name === 'FAILURE' && payloadDetails.data.status.message && (
          <Box className="wrapper-box">
            <CardErrorDetails errorMessage={payloadDetails.data.status.message} feedbackId={id} />
          </Box>
        )}
        {payloadDetails.data?.payload && showPayload && (
          <Box className="wrapper-box">
            <CardPayload
              segmentProps={{
                payload: payloadDetails.data?.payload,
                errorMessage: payloadDetails.data.status.message,
                status: badgeStatus,
                event,
                entity: payloadDetails.data?.entity.name,
                action: payloadDetails.data?.entity.operation,
                version: payloadDetails.data?.entity.version,
                step: recordSteps?.[0],
                sourceSystem: payloadDetails.data?.sourceSystem,
                traceId: payloadDetails.data?.parentTraceId,
              }}
              onDownload={downloadJSON}
              contentToCopy={payloadDetails.data?.payload ?? ''}
            >
              <Box>
                <ReactJson
                  style={{
                    borderBottomRightRadius: '10px',
                    borderBottomLeftRadius: '10px',
                    padding: '10px',
                    maxHeight: 420,
                    overflowY: 'scroll',
                  }}
                  src={dataJSON}
                  theme="monokai"
                  shouldCollapse={({ name }) => !!name}
                  name={false}
                />
              </Box>
            </CardPayload>
          </Box>
        )}
      </Box>
    </DataChunkProvider>
  );
}
export default DataChunkDetails;
