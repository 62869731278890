import { Card, Heading, Paragraph } from '@hexa-ui/components';
import { Box } from '@material-ui/core';
import { ElementLocationType, ElementType, SpecificApp } from 'analytics';
import { Feedback } from 'components/Feedback/Feedback';
import { DataChunkContext } from 'context/DataChunkContext';
import { formatMessage } from 'i18n/formatters';
import { useContext } from 'react';
import { colors } from 'themes/palette';

type CardErrorProps = {
  errorMessage?: string;
  feedbackId?: string;
};

function CardErrorDetails({ errorMessage, feedbackId }: CardErrorProps): JSX.Element {
  const { chunkDetails } = useContext(DataChunkContext);

  return (
    <Card
      data-testid="card-chunk-details"
      border="medium"
      elevated="small"
      style={{ padding: '24px' }}
    >
      <Heading size="H4" style={{ marginBottom: '8px' }}>
        {formatMessage({ id: 'MonitoringPage.ERROR_DETAILS' })}
      </Heading>
      <Box>
        <span className="text-primary">
          {formatMessage({ id: 'MonitoringPage.ERROR_MESSAGE' })}
        </span>
        <Paragraph
          size="small"
          css={{ color: colors.graySecondary, opacity: '56%', lineHeight: '20px' }}
        >
          {errorMessage}
        </Paragraph>
        {!!feedbackId && (
          <Feedback
            id={feedbackId}
            segmentProps={{
              element_location_type: ElementLocationType.Page,
              element_location_name: 'Error details',
              element_label: 'Was this information helpful?',
              element_name: 'Feedback',
              element_type: ElementType.Button,
              specific_app: SpecificApp.Monitoring,
              status: chunkDetails.status,
              data_chunk_trace_id: chunkDetails.traceId,
              data_chunk_step: chunkDetails.step,
              data_chunk_source_system: chunkDetails.sourceSystem,
              entity: chunkDetails.entity,
              action: chunkDetails.action,
              version: chunkDetails.version,
              data_chunk_error_message: chunkDetails.errorMessage,
              data_chunk_payload_present: !!chunkDetails.payload,
              user_feedback_root_question: 'Was this information helpful?',
              user_feedback_message_error_code: chunkDetails.errorCode,
            }}
            divider
          />
        )}
      </Box>
    </Card>
  );
}
export default CardErrorDetails;
