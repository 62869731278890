import { createContext, ReactNode, useMemo } from 'react';
import { useParams } from 'react-router-dom';

interface IDataChunkDetails {
  id: string;
  status: string;
  traceId: string;
  step: string;
  sourceSystem: string;
  entity: string;
  action: string;
  version: string;
  errorMessage: string;
  payload: string;
  errorCode: string;
}

interface IDataChunkContext {
  id: string;
  chunkDetails: IDataChunkDetails;
}

interface IDataChunkProvider {
  children: ReactNode;
  chunkDetails: IDataChunkDetails;
}

export const DataChunkContext = createContext<IDataChunkContext>({} as IDataChunkContext);

DataChunkContext.displayName = 'DataChunkContext';

export function DataChunkProvider({ children, chunkDetails }: IDataChunkProvider) {
  const { id } = useParams<{ id: string }>();

  const contextValue = useMemo(() => ({ id, chunkDetails }), [id, chunkDetails]);

  return <DataChunkContext.Provider value={contextValue}>{children}</DataChunkContext.Provider>;
}
